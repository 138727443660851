import Vue from 'vue'

Vue.filter('currency', (value, locale) => {
  const formatter = new Intl.NumberFormat(locale.replace('_', '-'), {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0
  })
  return formatter.format(value)
})
