const config = {
  apiBase: process.env.VUE_APP_API_BASE,
  appName: 'Sci-meet',
  version: process.env.VUE_APP_VERSION || '.dev',
  trumbo: {
    config: {
      resetCss: true,
      removeformatPasted: true,
      tagsToRemove: ['script', 'link', 'iframe', 'style'],
      btns: [
        // ['viewHTML'],
        ['undo', 'redo'], // Only supported in Blink browsers
        ['formatting_minimal'],
        ['strong', 'em', 'del'],
        ['superscript', 'subscript'],
        ['unorderedList', 'orderedList'],
        ['removeformat']
        // ['fullscreen']
      ],
      btnsDef: {
        formatting_minimal: {
          dropdown: ['p', 'blockquote', 'h3', 'h4'],
          ico: 'p'
        }
      }
    }
  }
}

export default config
