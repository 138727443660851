<template>
  <div class="text-center">
    <!--<svg class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" /> </svg>-->
    <img src="@/assets/images/icon.gif">
  </div>
</template>

<script>
export default {
  name: 'Loader'
}
</script>

<style lang="scss">
    .load-block {
        display: block;
        margin-top: 10%;
        margin-left: auto;
        margin-right: auto;
    }
</style>
