/** *********** library feast *************/
import Vue from 'vue'

import axios from 'axios'

import { auto } from 'browser-unhandled-rejection' // Applies polyfill if necessary to window.Promise

import GetTextPlugin from 'vue-gettext'

import Moment from 'moment'

import VueCookie from 'vue-cookie'

import Vuelidate from 'vuelidate'

import VuejsDialog from 'vuejs-dialog'
import ConfirmPrompt from './components/common/ConfirmPrompt'

import VueScrollTo from 'vue-scrollto'
import { datadogRum } from '@datadog/browser-rum';

/** *********** our stuff *************/
import config from './config'
import App from './App.vue'
import router from './router'
import Session from './resources/Session'
import translations from './i18n/translations.json'
import './filters'

// styling
import './bootstrap'
import './assets/scss/app.scss'
import { TooltipPlugin } from 'bootstrap-vue'

require('./polyfill')
window.$ = window.jQuery = require('jquery')
Vue.prototype.$http = axios.create({
  baseURL: config.apiBase
})
auto()
Vue.use(GetTextPlugin, {
  availableLanguages: {
    en_GB: 'English',
    es_ES: 'Español',
    pt_PT: 'Português'
  },
  defaultLanguage: 'en_GB',
  translations: translations,
  silent: true
})
Vue.prototype.moment = Moment
Vue.use(VueCookie)
Vue.use(Vuelidate)

Vue.use(VuejsDialog, {
  view: 'confirm-prompt',
  html: true
})

Vue.dialog.registerComponent('confirm-prompt', ConfirmPrompt)
Vue.use(VueScrollTo, { offset: -200 })
Vue.use(TooltipPlugin)

if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
      applicationId: '70aa110b-9961-4331-966b-de316da01316',
      clientToken: 'pub6b1dabb60bc051bd5db861276c052949',
      site: 'datadoghq.eu',
      service: 'conferences-fe',
      env: process.env.ENV_NAME,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: process.env.VUE_APP_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 64,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
  });
}

const app = new Vue({
  router,
  render: h => h(App),
  data: {
    session: null,
    alerts: {},
    config: config,
    loadingRoute: false
  },
  created: function () {
    const self = this

    // https://github.com/axios/axios#interceptors
    this.$http.interceptors.request.use(function (config) {
      if (!config.params) {
        config.params = {}
      }

      if (!config.params.locale) {
        config.params.locale = self.$language.current
      }

      return config
    })

    this.session = new Session(
      this.$http,
      this.$language,
      this.$cookie,
      Moment,
      this.$router
    )

    this.clearAlerts()
  },
  methods: {
    getPageName: function () {
      return this.$gettext(this.$route.meta.title)
    },
    getAlertVariantCss: function (variant) {
      return (variant === 'error') ? 'danger' : variant
    },
    /* @todo use plugin architecture for notifications */
    pushAlert: function (variant, message) {
      this.alerts = {
        success: [],
        error: []
      }

      this.alerts[variant] = [message]

      setTimeout(() => {
        $('.alert.alert-' + this.getAlertVariantCss(variant)).addClass('show')
      }, 100)
    },
    clearAlert: function (variant, $event) {
      $event.preventDefault()

      const self = this
      $('.alert.alert-' + this.getAlertVariantCss(variant)).removeClass('show')

      setTimeout(() => {
        self.alerts[variant] = []
      }, 300)
    },
    clearAlerts (variant) {
      if (variant !== undefined) {
        this.clearAlert(variant)
        return
      }

      $('.alert').removeClass('show')

      const self = this
      self.alerts = {
        success: [],
        error: []
      }
    }
  }
})

app.$mount('#app')
