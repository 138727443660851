import Vue from 'vue'

class AbstractResource {
   $http = null;

   /**
    * @type {boolean}
    */
   isFull = false;

   /**
    * @type {null|Integer}
    */
   id = null;

   constructor ($http, data, isFull) {
     this.$http = $http
     if (data) {
       this.setData(data, isFull)
     }
   }

   isNew () {
     return this.id === null
   }

   setData (data, isFull) {
     // deep copy
     data = JSON.parse(JSON.stringify(data))
     if (isFull) {
       this.isFull = isFull
     }

     for (const key in data) {
       if (data.hasOwnProperty(key)) {
         // ensure we have reactivity: https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats
         Vue.set(this, key, data[key])
       }
     }

     return this
   }

   getUri () {
     return this._links.self.href
   }

   delete () {
     return this.$http.delete(this.getUri())
   }

   /**
    * @param {Array|undefined} fields
    * @returns {Promise}
    */
   update (fields) {
     let data

     if (fields) {
       data = {}
       fields.forEach(val => {
         data[val] = this[val]
       })
     } else {
       data = this
     }

     return this.$http({
       method: 'put',
       url: this.getUri(),
       data: data
     })
   }

   /**
    * @returns {Promise}
    */
   fullFetch () {
     if (this.isFull) {
       return Promise.resolve()
     }

     return this.$http.get(this.getUri()).then(response => {
       this.setData(response.data, true)
     })
   }

   reload () {
     return this.fullFetch()
   }

   _buildFormData (formData, data, parentKey) {
     return AbstractResource._buildFormData(formData, data, parentKey)
   }

   validateFileType (file, extensionsAllowed) {
     if (!file.name) {
       return true
     }

     const components = file.name.split('.')
     const extension = components[components.length - 1].toLowerCase()
     return extensionsAllowed.includes(extension)
   }

   validateFileSize (file, maxSize) {
     return (file.size <= maxSize)
   }

   jsonToFormData (data) {
     return AbstractResource.jsonToFormData(data)
   }

   serialize () {
     return JSON.stringify(this)
   }

   create (endpoint) {
     return this.$http.post(endpoint, this)
   }
}

// thank you to whoever wrote this: https://plnkr.co/edit/24fsVLGn6rLEPhRDM0R7?p=info
AbstractResource._buildFormData = function (formData, data, parentKey) {
  if (typeof data === 'function') {
    return
  }

  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      AbstractResource._buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
    })
  } else {
    const value = data == null ? '' : data
    formData.append(parentKey, value)
  }
}

AbstractResource.jsonToFormData = function (data) {
  const formData = new FormData()
  AbstractResource._buildFormData(formData, data)
  return formData
}

AbstractResource.parse422Values = function (errorResponse) {
  const values = []
  for (const errorEntity in errorResponse.data.error.errors) {
    errorResponse.data.error.errors[errorEntity].forEach(val => {
      values.push(val)
    })
  }

  return values
}

export default AbstractResource
