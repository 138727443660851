import Vue from 'vue'
import Router from 'vue-router'

// Common components
const AreaSelector = () => import('./views/AreaSelector.vue')
const RouterContainer = () => import('./components/common/RouterContainer.vue')
const UserShow = () => import('./components/common/UserShow.vue')
const Program = () => import('./components/common/Program.vue')

// Personal Area components
const ParticipantArea = () => import('./views/ParticipantArea.vue')
const Dashboard = () => import('./components/participant/Dashboard.vue')
const PersonalData = () => import('./components/participant/PersonalData.vue')
const ParticipantDocuments = () => import('./components/participant/Documents.vue')
const ParticipantSocialAndAccommodation = () => import('./components/participant/SocialAndAccomodation.vue')
const ParticipantPublicPublications = () => import('./components/participant/PublicPublications.vue')

/// publications
const ParticipantPublicationsIndex = () => import('./components/participant/MyPublicationsIndex.vue')
const PublicationsForm = () => import('./components/participant/PublicationForm.vue')
const ParticipantFees = () => import('./components/participant/Fees.vue')
const ParticipantPayment = () => import('./components/participant/Payment.vue')
const PublicationsFormReview = () => import('./components/participant/PublicationReview.vue')

// Referee Area components
const ReviewerArea = () => import('./views/ReviewerArea.vue')
const ReviewerDashboard = () => import('./components/reviewer/Dashboard.vue')

// Admin (+Organizer+Editor) Area components
const AdminsArea = () => import('./views/AdminsArea.vue')
const AdminsDashboard = () => import('./components/admins/Dashboard.vue')
const AdminsPublicationsIndex = () => import('./components/admins/PublicationsIndex.vue')
const AdminsPublicationView = () => import('./components/admins/PublicationView.vue')
const AdminsPublicationAuthorsIndex = () => import('./components/admins/PublicationAuthorsIndex.vue')
const AdminsPublicationReviewersIndex = () => import('./components/admins/PublicationReviewersIndex.vue')
const AdminsPublicationScientificProgram = () => import('./components/admins/ScientificProgramIndex.vue')
const AdminsPublicationScientificProgramForm = () => import('./components/admins/ScientificProgramForm.vue')
const AdminsPublicationScientificProgramRooms = () => import('./components/admins/ScientificProgramRooms.vue')
const AdminsEventForm = () => import('./components/admins/EventForm.vue')
const AdminsEventCreate = () => import('./components/admins/EventCreate.vue')
const AdminsEventEmails = () => import('./components/admins/EventEmails.vue')
const AdminsEventIndex = () => import('./components/admins/EventsIndex.vue')
const AdminsRegistrationsIndex = () => import('./components/admins/RegistrationsIndex.vue')
const AdminsRegistrationsFile = () => import('./components/admins/RegistrationFile.vue')
const AdminsRegistrationsMailings = () => import('./components/admins/RegistrationMailings.vue')
const AdminsFiscalEntities = () => import('./components/admins/FiscalEntitiesList.vue')
const AdminsFiscalEntityForm = () => import('./components/admins/FiscalEntityForm.vue')
const AdminsUsersIndex = () => import('./components/admins/UsersIndex.vue')
const AdminsMembersIndex = () => import('./components/admins/MembersIndex.vue')
const AdminsMembersList = () => import('./components/admins/MembersList.vue')
const AdminsPaymentsIndex = () => import('./components/admins/PaymentsIndex.vue')
const AdminsPaymentsVatRates = () => import('./components/admins/PaymentVatRates.vue')
const AdminsDocumentTemplates = () => import('./components/admins/DocumentTemplates.vue')
const AdminsEventTeam = () => import('./components/admins/EventUsers/Index.vue')
const AdminsHotelsIndex = () => import('./components/admins/HotelsIndex.vue')
const AdminsHotelsShow = () => import('./components/admins/HotelsShow.vue')
const AdminsHotelsRegistrations = () => import('./components/admins/HotelsRegistrations.vue')
const AdminsActivitiesIndex = () => import('./components/admins/ActivitiesIndex.vue')
const AdminsActivitiesRegistrations = () => import('./components/admins/ActivitiesRegistrations.vue')
const AdminsActivitiesShow = () => import('./components/admins/ActivitiesShow.vue')
const AdminsLogs = () => import('./components/admins/Logs.vue')

// Guest
const Login = () => import('./views/Login.vue')
const GuestArea = () => import('./views/GuestArea.vue')
const RecoverPassword = () => import('./views/RecoverPassword.vue')
const Logout = () => import('./views/Logout.vue')
const ResetPassword = () => import('./views/ResetPassword')

// Registration flow
const GuestAccount = () => import('./components/guest/Account.vue')
const GuestData = () => import('./components/guest/PersonalData.vue')
const GuestClosing = () => import('./components/guest/Closing.vue')
const GuestSuccess = () => import('./views/RegistrationSuccess.vue')
const GuestActivate = () => import('./views/RegistrationActivate.vue')

// Others
const PaymentForwards = () => import('./views/PaymentForwards.vue')

Vue.use(Router)

function generateAdminRoutes (role) {
  return [
    {
      path: '/' + role,
      component: AdminsDashboard,
      meta: { title: $gettext('Dashboard'), isEntryPoint: true }
    },
    {
      name: role + '-account',
      path: '/' + role + '/user',
      component: UserShow,
      meta: { title: $gettext('Account Information'), noEventContext: true }
    },
    {
      path: '/' + role + '/config',
      meta: { title: $gettext('Config'), isEntryPoint: true },
      redirect: { name: role + '-config' },
      component: RouterContainer,
      children: [
        {
          name: role + '-config-users',
          path: '/' + role + '/config/users',
          component: RouterContainer,
          redirect: { name: role + '-config-users-index' },
          meta: { title: $gettext('User list'), noEventContext: true },
          children: [
            {
              name: role + '-config-users-index',
              path: '/' + role + '/config/users/index',
              component: AdminsUsersIndex,
              meta: { title: $gettext('User list'), isEntryPoint: true, noEventContext: true }
            },
            {
              name: role + '-config-users-new',
              path: '/' + role + '/config/users/new',
              component: UserShow,
              meta: { title: $gettext('Create admin'), noEventContext: true }
            },
            {
              name: role + '-config-users-show',
              path: '/' + role + '/config/users/:user_id',
              component: UserShow,
              meta: { title: $gettext('Edit user'), noEventContext: true }
            }
          ]
        },
        {
          name: role + '-config-logs',
          path: '/' + role + '/config/logs',
          component: AdminsLogs,
          meta: { title: $gettext('Activity log'), noEventContext: true }
        },
        {
          name: role + '-payments-vat',
          path: '/' + role + '/config/vat',
          component: AdminsPaymentsVatRates,
          meta: { title: $gettext('VAT Rates'), noEventContext: true }
        },
        {
          name: role + '-fiscal-entities-container',
          path: '/' + role + '/config/fiscal-entities',
          redirect: { name: role + '-fiscal-entities' },
          component: RouterContainer,
          meta: { title: $gettext('Fiscal entities'), noEventContext: true },
          children: [
            {
              name: role + '-fiscal-entities',
              path: '/' + role + '/config/fiscal-entities/index',
              component: AdminsFiscalEntities,
              meta: { title: $gettext('Fiscal entities'), noEventContext: true, isEntryPoint: true }
            },
            {
              name: role + '-fiscal-entities-create',
              path: '/' + role + '/config/fiscal-entities/new',
              component: AdminsFiscalEntityForm,
              meta: { title: $gettext('Create fiscal entity'), noEventContext: true }
            },
            {
              name: role + '-fiscal-entities-edit',
              path: '/' + role + '/config/fiscal-entities/:entity_id',
              component: AdminsFiscalEntityForm,
              meta: { title: $gettext('Edit fiscal entity'), noEventContext: true }
            }
          ]
        },
        {
          name: role + '-config-members',
          path: '/' + role + '/config/members',
          component: RouterContainer,
          redirect: { name: role + '-config-members-index' },
          meta: { title: $gettext('Members list'), noEventContext: true },
          children: [
            {
              name: role + '-config-members-index',
              path: '/' + role + '/config/members/index',
              component: AdminsMembersIndex,
              meta: { title: $gettext('Member lists'), isEntryPoint: true, noEventContext: true }
            },
            {
              name: role + '-config-members-list',
              path: '/' + role + '/config/members/:list_id',
              component: AdminsMembersList,
              meta: { title: $gettext('Members'), isEntryPoint: false, noEventContext: true }
            }
          ]
        }
      ]
    },
    {
      path: '/' + role + '/events',
      meta: { title: $gettext('Events') },
      redirect: { name: role + '-events-list' },
      component: RouterContainer,
      children: [
        {
          name: role + '-events-list',
          path: '/' + role + '/events/list',
          component: AdminsEventIndex,
          meta: { title: $gettext('Event list'), isEntryPoint: true, noEventContext: true }
        },
        {
          name: role + '-events-emails',
          path: '/' + role + '/events/emails',
          component: AdminsEventEmails,
          meta: { title: $gettext('Emails'), noEventContext: true }
        },
        {
          name: role + '-events-new',
          path: '/' + role + '/events/new',
          component: AdminsEventCreate,
          meta: { title: $gettext('Create event'), noEventContext: true }
        },
        {
          name: role + '-events-config',
          path: '/' + role + '/events/config',
          component: AdminsEventForm,
          meta: { title: $gettext('Event configuration') }
        },
        {
          name: role + '-events-templates',
          path: '/' + role + '/events/templates',
          component: AdminsDocumentTemplates,
          meta: { title: $gettext('Templates'), noEventContext: true }
        },
        {
          name: role + '-events-team',
          path: '/' + role + '/events/team',
          component: AdminsEventTeam,
          meta: { title: $gettext('Event Team') }
        }
      ]
    },
    {
      path: '/' + role + '/publications',
      meta: { title: $gettext('Publications') },
      redirect: { name: role + '-publications-list' },
      component: RouterContainer,
      children: [
        {
          name: role + '-publications-list',
          path: '/' + role + '/publications/list',
          component: AdminsPublicationsIndex,
          meta: { title: $gettext('Publication Listing'), isEntryPoint: true }
        },
        {
          name: role + '-publications-authors',
          path: '/' + role + '/publications/authors',
          component: AdminsPublicationAuthorsIndex,
          meta: { title: $gettext('Authors') }
        },
        {
          name: role + '-publications-reviewers',
          path: '/' + role + '/publications/reviewers',
          component: AdminsPublicationReviewersIndex,
          meta: { title: $gettext('Reviewers') }
        },
        {
          path: '/' + role + '/publications/new',
          component: PublicationsForm,
          meta: { title: $gettext('Create publication'), noEventContext: true },
          name: role + '-publications-new'
        },
        {
          name: role + '-publications-edit',
          path: '/' + role + '/publications/:publication_id',
          component: AdminsPublicationView,
          meta: { title: $gettext('Publication'), noEventContext: true }
        }
      ]
    },
    {
      name: role + '-program-container',
      path: '/' + role + '/program',
      redirect: { name: role + '-program-list' },
      component: RouterContainer,
      meta: { title: $gettext('Scientific Program') },
      children: [
        {
          name: role + '-program-list',
          path: '/' + role + '/program/list',
          component: AdminsPublicationScientificProgram,
          meta: { title: $gettext('Manage program'), isEntryPoint: true }
        },
        {
          name: role + '-program-timetable',
          path: '/' + role + '/program/timetable',
          component: Program,
          meta: { title: $gettext('View program') }
        },
        {
          name: role + '-program-rooms',
          path: '/' + role + '/program/rooms',
          component: AdminsPublicationScientificProgramRooms,
          meta: { title: $gettext('Conference Rooms') }
        },
        {
          name: role + '-program-new',
          path: '/' + role + '/program/new',
          component: AdminsPublicationScientificProgramForm,
          meta: { title: $gettext('Create session') }
        },
        {
          name: role + '-program-edit',
          path: '/' + role + '/program/:session_id',
          component: AdminsPublicationScientificProgramForm,
          meta: { title: $gettext('Edit session'), noEventContext: true }
        }
      ]
    },
    {
      path: '/' + role + '/registrations',
      meta: { title: $gettext('Registrations') },
      redirect: { name: role + '-registrations-list' },
      component: RouterContainer,
      children: [
        {
          name: role + '-registrations-list',
          path: '/' + role + '/registrations/list',
          component: AdminsRegistrationsIndex,
          meta: { title: $gettext('Registrations'), isEntryPoint: true }
        },
        {
          name: role + '-registration-mailings',
          path: '/' + role + '/registrations/mailings',
          component: AdminsRegistrationsMailings,
          meta: { title: $gettext('Mailings') }
        },
        {
          name: role + '-registration-file',
          path: '/' + role + '/registrations/:registration_id',
          component: AdminsRegistrationsFile,
          meta: { title: $gettext('Registration'), noEventContext: true }
        }
      ]
    },
    {
      path: '/' + role + '/payments',
      meta: { title: $gettext('Payments') },
      redirect: { name: role + '-payments-list' },
      component: RouterContainer,
      children: [
        {
          name: role + '-payments-list',
          path: '/' + role + '/payments/list',
          component: AdminsPaymentsIndex,
          meta: { title: $gettext('Payments'), isEntryPoint: true }
        }
      ]
    },
    {
      path: '/' + role + '/activities',
      meta: { title: $gettext('Social Program') },
      redirect: { name: role + '-activities-list' },
      component: RouterContainer,
      children: [
        {
          name: role + '-activities-list',
          path: '/' + role + '/activities/list',
          component: AdminsActivitiesIndex,
          meta: { title: $gettext('Social Program'), isEntryPoint: true }
        },
        {
          name: role + '-activities-new',
          path: '/' + role + '/activities/new',
          component: AdminsActivitiesShow,
          meta: { title: $gettext('Create activity') }
        },
        {
          name: role + '-activities-registrations',
          path: '/' + role + '/activities/registrations',
          component: AdminsActivitiesRegistrations,
          meta: { title: $gettext('Social registrations') }
        },
        {
          name: role + '-activities-edit',
          path: '/' + role + '/activities/:activity_id',
          component: AdminsActivitiesShow,
          meta: { title: $gettext('Update activity') }
        }
      ]
    },
    {
      path: '/' + role + '/hotels',
      meta: { title: $gettext('Hotels') },
      redirect: { name: role + '-hotels-list' },
      component: RouterContainer,
      children: [
        {
          name: role + '-hotels-list',
          path: '/' + role + '/hotels/list',
          component: AdminsHotelsIndex,
          meta: { title: $gettext('Hotels'), isEntryPoint: true }
        },
        {
          name: role + '-hotels-new',
          path: '/' + role + '/hotels/new',
          component: AdminsHotelsShow,
          meta: { title: $gettext('Create hotel') }
        },
        {
          name: role + '-hotels-registrations',
          path: '/' + role + '/hotels/registrations',
          component: AdminsHotelsRegistrations,
          meta: { title: $gettext('Hotel registrations') }
        },
        {
          name: role + '-hotels-edit',
          path: '/' + role + '/hotels/:hotel_id',
          component: AdminsHotelsShow,
          meta: { title: $gettext('Update hotel') }
        }
      ]
    }
  ]
}

// placeholder for annotations
function $gettext (text) {
  return text
}

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  linkActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'area-selector',
      component: AreaSelector,
      meta: { title: $gettext('Welcome') }
    },
    {
      path: '/payment-forwards',
      name: 'payment-forwards',
      component: PaymentForwards,
      meta: { title: 'Payment forwards' }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { title: $gettext('Login') }
    },
    {
      path: '/logout',
      name: 'logout',
      component: Logout,
      meta: {}
    },
    {
      path: '/register/:event_name',
      component: GuestArea,
      meta: { title: $gettext('Registration') },
      children: [
        {
          path: '',
          component: GuestAccount,
          name: 'guest-account',
          meta: { title: $gettext('Registration'), step: 'account' }
        },
        {
          path: 'personal',
          component: GuestData,
          name: 'guest-personal',
          meta: { title: $gettext('Personal Data'), step: 'personal' }
        },
        {
          path: 'closing',
          component: GuestClosing,
          name: 'guest-confirmation',
          meta: { title: $gettext('Confirmation'), step: 'closing' }
        }
      ]
    },
    {
      path: '/registration-success',
      component: GuestSuccess,
      name: 'guest-success',
      meta: { title: $gettext('Success'), step: 'success' }
    },
    {
      path: '/registration-activate',
      component: GuestActivate,
      name: 'guest-activate',
      meta: { title: $gettext('Registration confirmation') }
    },
    {
      path: '/password',
      name: 'password',
      component: RecoverPassword,
      meta: { title: $gettext('Recover Password') }
    },
    {
      path: '/recover',
      name: 'reset',
      component: ResetPassword,
      meta: { title: $gettext('Reset Password') }
    },
    {
      path: '/reviewer',
      component: ReviewerArea,
      meta: { isEntryPoint: true },
      children: [
        {
          path: 'user',
          component: UserShow,
          name: 'reviewer-user',
          meta: { title: $gettext('Account Information'), noEventContext: true }
        },
        {
          path: '',
          component: ReviewerDashboard,
          name: 'reviewer-area',
          meta: { title: $gettext('Reviewer area') }
        }
      ]
    },
    {
      path: '/editor',
      name: 'editor-area',
      component: AdminsArea,
      meta: { title: $gettext('Editor area') },
      props: { roleContext: 'editor' },
      children: generateAdminRoutes('editor')
    },
    {
      path: '/organizer',
      name: 'organizer-area',
      component: AdminsArea,
      meta: { title: $gettext('Organizer area') },
      props: { roleContext: 'organizer' },
      children: generateAdminRoutes('organizer')
    },
    {
      path: '/admin',
      name: 'admin-area',
      component: AdminsArea,
      meta: { title: $gettext('Administration') },
      props: { roleContext: 'admin' },
      children: generateAdminRoutes('admin')
    },
    {
      path: '/participant',
      component: ParticipantArea,
      meta: { title: $gettext('Participant area') },
      children: [
        {
          name: 'participant-area',
          path: '',
          component: Dashboard,
          meta: { title: $gettext('Participant area'), isEntryPoint: true }
        },
        {
          path: 'user',
          name: 'participant-user',
          component: UserShow,
          meta: { title: $gettext('Account Information'), noEventContext: true }
        },
        {
          name: 'participant-data',
          path: 'data',
          component: PersonalData,
          meta: { title: $gettext('Personal Data') }
        },
        {
          name: 'participant-fees',
          path: 'fees',
          component: ParticipantFees,
          meta: { title: $gettext('Fee selection') }
        },
        {
          name: 'participant-payment',
          path: 'payment',
          component: ParticipantPayment,
          meta: { title: $gettext('Payment') }
        },
        {
          name: 'participant-documents',
          path: 'documents',
          component: ParticipantDocuments,
          meta: { title: $gettext('Documents') }
        },
        {
          name: 'participant-social',
          path: 'social',
          component: ParticipantSocialAndAccommodation,
          meta: { title: $gettext('Social Program & Accomodation') }
        },
        {
          name: 'participant-program',
          path: 'program',
          component: Program,
          meta: { title: $gettext('Scientific Program') }
        },
        {
          name: 'participant-public-publications',
          path: 'publications',
          component: ParticipantPublicPublications,
          meta: { title: $gettext('Publications') }
        },
        {
          path: 'my-publications',
          component: RouterContainer,
          meta: { title: $gettext('My publications') },
          redirect: { name: 'participant-my-publications' },
          children: [
            {
              path: '',
              component: ParticipantPublicationsIndex,
              meta: { title: $gettext('My publications'), isEntryPoint: true },
              name: 'participant-my-publications'
            },
            {
              path: 'new',
              component: PublicationsForm,
              meta: { title: $gettext('New publication') },
              name: 'participant-publications-new'
            },
            {
              path: ':publication_id',
              component: PublicationsForm,
              meta: { title: $gettext('Edit publication') },
              name: 'participant-publications-edit'
            },
            {
              path: ':publication_id/review',
              component: PublicationsFormReview,
              meta: { title: $gettext('Review publication') },
              name: 'participant-publications-review'
            }
          ]
        }
      ]
    },
    { path: '*', redirect: '/' }
  ]
})

export default router
