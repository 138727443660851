<template>
  <div
    id="confirm_prompt"
    class="modal fade in"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div
          class="modal-header"
          v-if="messageHasTitle"
        >
          <h4
            class="modal-title"
            id="reviewerModalLabel"
          >
            {{ messageTitle }}
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <i class="fas fa-times" />
          </button>
        </div>
        <div class="modal-body">
          <p v-html="messageBody" />
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary btn-md text-uppercase waves-effect waves-light"
            @click="handleResolve()"
          >
            <!-- <i class="fas fa-trash"></i> --> <translate>Confirm</translate>
          </button>
          <button
            type="button"
            class="btn btn-md btn-secondary waves-effect hidden-sm-down"
            @click="handleDismiss()"
          >
            <translate>Cancel</translate>
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import DialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min' // Include mixin

export default {
  mixins: [DialogMixin],
  mounted () {
    $('#confirm_prompt').modal('show')
  },
  beforeDestroy () {
    $('#confirm_prompt').modal('hide')
  },
  methods: {
    handleResolve () {
      this.proceed() // included in DialogMixin
    },
    handleDismiss () {
      this.cancel() // included in DialogMixin
    }
  }
}
</script>

<style scoped="" lang="scss">

</style>
