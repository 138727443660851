import User from './User'

// our little friend
/**
 * Deals with client persistence
 */
class Session {
  $http = null;
  $language = null;
  $cookie = null;
  moment = null;

  /**
   * JWT token for user
   * @type {Object}
   */
  jwtTokenUser = null;

  /**
   * Client data
   * @type {Object}
   */
  clientData = null;

  /**
   * /auth/me response
   * @type {Object}
   */
  userData = null;

  roleList = null;

  tokenTimer = null;

  constructor ($http, $language, $cookie, moment, $router) {
    this.$http = $http
    this.$language = $language
    this.$cookie = $cookie
    this.moment = moment
    this.$router = $router

    this._loadLanguage()
  }

  _loadLanguage () {
    if (this.$cookie.get('ln')) {
      this.switchLang(this.$cookie.get('ln'))
    } else {
      let userLang = window.navigator.userLanguage || window.navigator.language
      userLang = userLang.replace('-', '_')
      if (this.$language.available[userLang]) {
        this.switchLang(userLang)
      } else {
        this.switchLang('en_GB')
      }
    }
  }

  /**
   *
   * @returns {Promise}
   */
  load (loginToken = null) {
    this._loadUserSessionFromStorage(loginToken)
    return this._loadClientData()
  }

  _loadClientData () {
    return this._queryClientData()
  }

  _queryClientData () {
    return this.$http.post('auth/client', {}).then(response => {
      this.clientData = response.data.client
      window.localStorage.setItem('clientData', JSON.stringify(this.clientData))

      if (response.data.user) {
        this.userData = new User(this.$http, response.data.user)
      }

      return Promise.resolve(this.clientData)
    })
  }

  async _loadUserSessionFromStorage (loginToken) {
    if (loginToken) {
      this.setTokenData({ token: loginToken })
      return
    }

    const storageData = JSON.parse(window.localStorage.getItem('jwtTokenUser'))
    if (storageData) {
      this.setTokenData(storageData)
    }
  }

  hasToken () {
    return (this.jwtTokenUser)
  }

  sendToLogin () {
    this.$router.push({
      name: 'login',
      query: {
        redirect: this.$router.currentRoute.path,
        error: 'session_expired'
      }
    })
  }

  refresh () {
    if (this._getRemainingSeconds() < 0) {
      // too late...
      this.logout()
      this.sendToLogin()
      return
    }

    this.$http.post('/auth/refresh').then(response => {
      const data = response.data
      data.expires_at = this._getTime() + data.expires_in
      this.setTokenData(data)
    }).catch(e => {
      this.logout()
      this.sendToLogin()
    })
  }

  hasRoleForEvent (name, eventId) {
    if (!this.isLogged()) {
      return false
    }

    return this.userData.roles.filter(role => role.name === name && role.event_id === eventId).length > 0
  }

  switchLang (langKey) {
    if (!this.$language.available[langKey]) {
      return
    }

    this.$language.current = langKey
    this.moment.locale(langKey)
    this.$cookie.set('ln', langKey, { expires: '1Y' })
    this.$http.defaults.params = { locale: langKey }
  }

  getUser () {
    return this.userData
  }

  setUser (data) {
    this.userData = new User(this.$http, data)
  }

  isLogged () {
    if (!this.hasToken()) {
      return false
    }

    if (this._getRemainingSeconds() < 0) {
      // token expired, bad luck
      this.logout()
      return false
    }

    return true
  }

  getUserRoleList () {
    if (!this.isLogged()) {
      return []
    }

    if (this.roleList === null) {
      this.roleList = []
      this.userData.roles.forEach(role => {
        if (this.roleList.indexOf(role.name) === -1) {
          this.roleList.push(role.name)
        }
      })
    }

    return this.roleList
  }

  permissionCheck (role) {
    if (!this.isLogged()) {
      return false
    }
    return (this.getUserRoleList().includes(role))
  }

  getPrimaryCategory () {
    // from top to bottom
    const systemRoles = ['admin', 'organizer', 'editor', 'reviewer', 'participant']

    for (const i in systemRoles) {
      if (this.permissionCheck(systemRoles[i])) {
        return systemRoles[i]
      }
    }
  }

  getClientData () {
    return this.clientData
  }

  /**
   * @return {Promise}
   */
  login (email, password) {
    return new Promise((resolve, reject) => {
      return this.$http.post('auth/login', {
        client_id: this.getClientId(),
        email: email,
        password: password
      }).then(response => {
        const data = response.data.token
        const user = response.data.user
        this.userData = new User(this.$http, user)
        data.expires_at = this._getTime() + data.expires_in
        this.setTokenData(data)
        return resolve(true)
      }).catch(error => {
        if (error.response && error.response.status === 403) {
          return reject({ code: Session.ERROR_WRONG_CREDENTIALS, message: 'Invalid user/password.' })
        }

        console.error(error)
        return reject({ code: null, message: 'Connection error.' })
      })
    })
  }

  addParticipantRole (role, eventId) {
    this.userData.roles.push({
      event_id: eventId,
      name: role
    })
    return this
  }

  setTokenData (data) {
    const struct = 'jwtTokenUser'

    if (!this.jwtTokenUser) { // initial load/login
      this[struct] = data
    } else { // refresh
      this[struct] = Object.assign(this.jwtTokenUser, data)
    }

    window.localStorage.setItem(struct, JSON.stringify(this[struct]))
    this.$http.defaults.headers.common.Authorization = 'Bearer ' + this.getToken()

    // handle token refresh 1 minute before session is expiring
    if (this.tokenTimer) {
      window.clearTimeout(this.tokenTimer)
    }

    this.tokenTimer = window.setTimeout(
      () => { this.refresh() },
      (this._getRemainingSeconds() - 60) * 1000
    )

    return this
  }

  logout () {
    this.jwtTokenUser = null
    this.roleList = null
    this.userData = null

    delete this.$http.defaults.headers.common.Authorization
    window.localStorage.removeItem('jwtTokenUser')
    window.localStorage.removeItem('registration')
    window.clearTimeout(this.tokenTimer)

    // reload client data
    this._queryClientData()
  }

  // aux function, gets unixtimestamp (in seconds)
  _getTime () {
    return Math.floor(Date.now() / 1000)
  }

  _getExpirationTime () {
    if (!this.hasToken()) {
      throw Error('No token!')
    }

    return this.jwtTokenUser.expires_at
  }

  _getRemainingSeconds () {
    return this._getExpirationTime() - this._getTime()
  }

  _getRemainingMinutes () {
    return Math.floor(this._getRemainingSeconds() / 60)
  }

  getClientId () {
    return this.clientData.id
  }

  getClientName () {
    return this.clientData.name
  }

  getClientShortName () {
    return this.clientData.short_name
  }

  getClientWebsite () {
    return this.clientData.website
  }

  getClientLogo () {
    return this.clientData.logo
  }

  getClientEmail () {
    return this.clientData.client_email
  }

  hasMemberList () {
    return this.clientData.has_members
  }

  getToken () {
    if (!this.hasToken()) {
      throw Error('No token!')
    }

    if (this.jwtTokenUser) {
      return this.jwtTokenUser.token
    }

    return this.clientData.token
  }

  getContextualData () {
    let data = {
      page: window.location.href,
      navigator: navigator.userAgent,
      client_id: (this.clientData) ? this.getClientId() : null
    }

    if (this.hasToken()) {
      data = Object.assign(data, {
        expires_at: new Date(this._getExpirationTime() * 1000),
        user_id: (this.userData) ? this.userData.id : null
      })
    }

    return data
  }
}

Session.ERROR_ACCOUNT_DEACTIVATED = 1
Session.ERROR_WRONG_CREDENTIALS = 2

export default Session
