Promise.prototype.finally = Promise.prototype.finally || {
  /* https://stackoverflow.com/questions/53327711/how-to-add-a-polyfill-to-support-finally-in-edge */
  finally (fn) {
    const onFinally = value => Promise.resolve(fn()).then(() => value)
    return this.then(
      result => onFinally(result),
      reason => onFinally(Promise.reject(reason))
    )
  }
}.finally
