<template>
  <div id="app">
    <div
      class="card-body bg-light-info text-center error-box"
      v-if="error === 'network'"
    >
      <p class="text-warning">
        <i class="fas fa-exclamation-triangle fa-5x" />
      </p>
      <h1 v-translate>
        No connection
      </h1>
      <p v-translate>
        Either your internet connection is facing issues or our services are offline.
      </p>

      <button
        class="btn btn-outline-primary"
        @click="reload()"
        v-translate
      >
        Try again
      </button>
    </div>
    <div
      class="card-body bg-light-warning text-center error-box"
      v-if="error === 'access'"
    >
      <p class="text-danger">
        <i class="fas fa-door-closed fa-5x" />
      </p>
      <h1 v-translate>
        Access denied
      </h1>
      <p v-translate>
        You are not authorised to access the requested page.
      </p>

      <button
        class="btn btn-outline-primary ml-2"
        @click="goHome()"
        v-translate
      >
        Return to dashboard
      </button>
    </div>
    <div
      class="card-body bg-light-danger text-center error-box"
      v-else-if="error === true"
    >
      <p class="text-danger">
        <i class="fas fa-exclamation-circle fa-5x" />
      </p>
      <h1 v-translate>
        Error
      </h1>
      <p>
        <translate>Something went wrong. Please try again or contact us via feedback@sci-meet.com</translate>
        <span v-if="errorData">&nbsp;and include the code displayed below</span>.
      </p>

      <button
        class="btn btn-outline-primary"
        @click="reload()"
        v-translate
      >
        Reload
      </button>
      <button
        class="btn btn-outline-primary ml-2"
        @click="goHome()"
        v-translate
      >
        Return to dashboard
      </button>

      <pre
        v-if="errorData"
        style="white-space: pre-wrap; word-wrap: break-word;"
      >{{ errorData }}</pre>
    </div>
    <Loader
      v-else-if="loadingToken"
      class="load-block"
    />
    <router-view v-else />
  </div>
</template>
<style>
  .error-box button {
    margin-bottom: 12px;
  }

  .error-box {
    height: 100vh;
  }
</style>
<script>
import Loader from './components/common/Loader'
export default {
  components: { Loader },
  data () {
    return {
      loadingToken: true,
      error: false,
      errorData: null
    }
  },
  watch: {
    error (newVal) {
      if (newVal) {
        $('.modal-backdrop').remove()
      }
    }
  },
  methods: {
    reload () {
      location.reload()
    },
    goHome () {
      window.location.href = this.$router.resolve({ name: 'area-selector' }).href
    }
  },
  created () {
    window.addEventListener('unhandledrejection', event => {
      if (!event.reason) {
        console.error(event)
        this.error = true
        return
      }

      const reloadConditions = [
        'Loading CSS chunk',
        'Loading chunk'
      ]

      if (event.reason.message) {
        reloadConditions.forEach(condition => {
          if (event.reason.message.includes(condition)) {
            // reload window with added force_reload query parameter
            if (window.location.href.includes('force_reload=')) {
              this.error = true
              this.errorData = "Chunk not found"
              return
            }

            // get timestamp
            const timestamp = new Date().getTime()

            if (!window.location.href.includes('?')) {
              window.location.href = `${window.location.href}?force_reload=${timestamp}`
              return
            }

            window.location.href = `${window.location.href}&force_reload=${timestamp}`
          }
        })
      }

      if (event.reason.message.includes('Navigation cancelled')) {
        return
      }

      const networkMessages = [
        'timeout of 0ms exceeded',
        'Network Error'
      ]

      if (event.reason.message) {
        networkMessages.forEach(message => {
          if (event.reason.message.includes(message)) {
            this.error = 'network'
            this.errorData = JSON.stringify(event)
          }
        })

        if (this.error) {
          return
        }
      }

      console.error(event)

      const report = {}

      if (event.reason.response) {
        report.code = event.reason.response.status
        report.response = event.reason.response.data
        report.request = {
          method: event.reason.config.method,
          url: event.reason.request.responseURL
        }

        if (event.reason.response.status === 403) {
          // dealing with unhandledrejection pollyfill bug
          if (event.reason.request.responseURL.includes('auth/login')) {
            return
          }

          this.error = 'access'
          return
        }
      } else {
        report.name = event.reason.name
        report.message = event.reason.message
        report.stack = event.reason.stack

        if (event.reason.request) {
          report.request = {
            method: event.reason.config.method,
            url: event.reason.request.responseURL
          }
        }
      }

      this.error = true
      this.errorData = btoa(JSON.stringify(report))
    })

    this.$router.onReady(() => {
      const loginToken = this.$route.query.loginToken || null

      if (this.$route.query.locale) {
        this.$root.session.switchLang(this.$route.query.locale)
      }

      this.$root.session.load(loginToken).then(() => {
        this.loadingToken = false
      })
    })
  }
}
</script>
